import logo from './logo.svg';
import './App.css';
import About from '../src/components/Navbar'

function App() {
  return (
    <>
    <About/>
    </>
  );
}

export default App;
